<template lang="pug">
    div.pa-10#produto
        PageTitle(label="Produtos")

        div(v-acl.cant.hide='`product.list`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='`product.list`')
            v-data-table(
                fixed-header
                :headers="headers"
                :items="products.data"
                :items-per-page="products.per_page"
                class="elevation-1"
                :loading='products.data.length <= 0'
                loading-text="Carregando... Aguarde!"
                hide-default-footer
                )
                template(v-slot:item.price="{ item }") {{item.price | money}}
                
            paginate( :data="products" )
</template>

<script>

export default {
    name: "ProductsList",
    components:{
        paginate: () => import( '@/components/Paginate' ),
        PageTitle: () => import( '@/components/PageTitle' ),
    },
    data(){
        return {
            params: {},
            headers: [
                {
                    text: '#',
                    value: 'id',
                    sortable: false,
                },
                {   text: 'SKU', value: 'sku', align: 'center', sortable: false  },
                {   text: 'Produtos', value: 'label', sortable: false  },
                {   text: 'Un.', value: 'unit', align: 'center', sortable: false  },
                {   text: 'Preço', value: 'price', align: 'center', sortable: false  },
            ],
            products: {
                data: []
            }
        }
    },

    created: function() {
        this.params = {...this.$route.query}// clone prevent bug
        this.loadData()
    },


    methods: {
        loadData: async function(){
            if ( this.acl('product.list') ) {
                let res = await this.api.get( 'products', this.params )
                this.products = res.data
            }
        },
        
    },

    


};
</script>
